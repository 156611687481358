var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper container",staticStyle:{"padding":"0px !important","height":"100%"}},[(_vm.activity && _vm.activity.tasks)?_c('div',{attrs:{"id":"grid"}},[_c('div',{staticClass:"font-weight-black",attrs:{"id":"intro"}},[_vm._v("Find dette sted ....")]),_vm._l((_vm.orderedTasks),function(task){return _c('div',{key:task.id,staticClass:"tasks text-center"},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"unset","font-size":"18px"},attrs:{"to":{
          name: 'task',
          params: {
            slug: _vm.$route.params.slug,
            activitySlug: _vm.$route.params.activitySlug,
            taskId: task.id,
          },
        }}},[_c('div',{staticClass:"task"},[_c('TaskImage',{attrs:{"level":task.level,"image_path":task.icon_path}}),_c('h6',{staticClass:"text-center"},[_vm._v(" "+_vm._s(task.name)+" ")])],1)])],1)})],2):_vm._e(),(_vm.activity !== 404)?_c('portal',{attrs:{"to":"app-bar-title"}},[_vm._v(_vm._s(_vm.activity.name))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }