import JSConfetti from "js-confetti";

export default {
  namespaced: true,
  actions: {
    showConfetti(_, { level }) {
      var colors = {
        1: ["#D3D3D3", "#C0C0C0", "#A9A9A9", "#808080"],
        2: ["#FFDF00", "#D4AF37", "#CFB53B", "#C5B358"],
      };
      const jsConfetti = new JSConfetti();
      jsConfetti.addConfetti({
        confettiColors: colors[level],
      });
    },
  },
};
