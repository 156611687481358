<template>
  <div class="wrapper container" style="padding: 0px !important; height: 100%">
    <div id="grid" v-if="activity && activity.tasks">
      <div id="intro" class="font-weight-black">Find dette sted ....</div>
      <div
        class="tasks text-center"
        v-for="task in orderedTasks"
        :key="task.id"
      >
        <router-link
          :to="{
            name: 'task',
            params: {
              slug: $route.params.slug,
              activitySlug: $route.params.activitySlug,
              taskId: task.id,
            },
          }"
          style="text-decoration: none; color: unset; font-size: 18px"
        >
          <div class="task">
            <TaskImage :level="task.level" :image_path="task.icon_path" />
            <h6 class="text-center">
              {{ task.name }}
            </h6>
          </div>
        </router-link>
      </div>
    </div>

    <portal to="app-bar-title" v-if="activity !== 404">{{
      activity.name
    }}</portal>
  </div>
</template>

<script>
import TaskImage from "../components/TaskImage";
import _ from "lodash";

export default {
  name: "home-page",
  data() {
    return {
      forceRefresh: 0,
    };
  },

  computed: {
    activity() {
      this.forceRefresh; // just referencing it is enough!
      return this.$store.getters["churches/getActivity"](
        this.$route.params.slug,
        this.$route.params.activitySlug
      );
    },
    orderedTasks: function () {
      let tasks = Object.assign([], this.activity.tasks).filter(
        (task) => !task.hidden
      );
      console.log("orderedTasks -> showSecret", this.showSecret);
      return _.orderBy(tasks, "sort");
    },
    // showSecret: function () {
    //   return Object.assign([], this.activity.tasks)
    //     .filter((task) => task.secret == 0)
    //     .every(
    //       (task) =>
    //         (["question", "open-question"].includes(task.type) &&
    //           task.level == 2) ||
    //         (!["question", "open-question"].includes(task.type) &&
    //           task.level > 0)
    //     );
    // },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "activities/addActivity") {
        this.forceRefresh++;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  components: {
    TaskImage,
  },
};
</script>
<style scoped>
#grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 30px;
  grid-auto-rows: clamp(150px, 22vh, 300px);
  height: 100%;
  grid-gap: 0px 20px;
  padding: 12px;
  padding-bottom: 22px;
}
#intro {
  grid-column: 1 / 3;
  text-align: center;
}
.task {
  display: grid;
  grid-template-rows: 1fr 1vh;
  height: calc(20vh - 20px);
  justify-content: center;
}

.tasks:last-child:nth-child(2n) {
  grid-column: span 2;
}
</style>
