<template>
  <div id="reader"></div>
</template>

<script>
import { Html5Qrcode } from "html5-qrcode";
export default {
  data() {
    return {
      scanner: null,
    };
  },
  props: {
    qrbox: {
      type: Number,
      default: 250,
    },
    fps: {
      type: Number,
      default: 10,
    },
  },
  mounted() {
    const config = {
      fps: this.fps,
      qrbox: this.qrbox,
    };
    this.scanner = new Html5Qrcode(/* element id */ "reader");
    this.scanner
      .start(
        { facingMode: "environment" },
        config,
        this.onScanSuccess,
        (errorMessage) => {
          console.log("Html5Qrcode.start()", errorMessage);
        }
      )
      .catch((err) => {
        console.log("Html5Qrcode.start().catch()", err);
      });
  },
  beforeDestroy() {
    console.log(
      `At this point, watchers, child components, and event listeners have not been teared down yet.`
    );
    // Perform the teardown procedure for exampleLeakyProperty.
    // (In this case, effectively nothing)
    this.scanner.stop();
  },
  methods: {
    onScanSuccess(decodedText, decodedResult) {
      this.$emit("result", decodedText, decodedResult);
    },
  },
};
</script>
