import Vue from "vue";
import Vuex from "vuex";
import churches from "./modules/churches";
import snackbar from "./modules/snackbar";
import confetti from "./modules/confetti";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    churches,
    snackbar,
    confetti,
  },
  mutations: {
    initialiseStore(state) {
      console.log("initialiseStore", state);
      // Check if the ID exists
      if (localStorage.getItem("store")) {
        // Replace the state object with the stored item
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem("store")))
        );
      }
    },
  },
});
