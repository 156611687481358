<template>
  <v-list nav>
    <v-list-item
      link
      :to="{ name: 'home', params: { slug: this.$route.params.slug } }"
      exact
    >
      <v-list-item-icon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.96 27.52">
          <path
            fill="none"
            stroke="#6e7c8f"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2px"
            d="M1,9.93L12.48,1l11.48,8.93v14.03c0,1.41-1.14,2.55-2.55,2.55H3.55c-1.41,0-2.55-1.14-2.55-2.55V9.93Z"
          />
          <path
            fill="none"
            stroke="#6e7c8f"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2px"
            d="M8.65,26.52V13.76h7.66v12.76"
          />
        </svg>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Forside</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      link
      :to="{ name: 'livline', params: { slug: this.$route.params.slug } }"
    >
      <v-list-item-icon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.85 30.85">
          <path
            fill="#6e7c8f"
            id="Icon_metro-help"
            class="cls-1"
            d="M15.42,0C6.91,0,0,6.91,0,15.42s6.91,15.42,15.42,15.42,15.42-6.91,15.42-15.42h0C30.85,6.91,23.94,0,15.42,0Zm-5.78,15.42c0-3.19,2.59-5.78,5.78-5.78s5.78,2.59,5.78,5.78-2.59,5.78-5.78,5.78-5.78-2.59-5.78-5.78h0Zm18.25,5.17l-5.34-2.22c.78-1.89,.78-4.01,0-5.9l5.34-2.21c1.37,3.31,1.37,7.02,0,10.33ZM20.59,2.95l-2.21,5.34c-1.89-.78-4.01-.78-5.9,0l-2.22-5.34c3.31-1.37,7.02-1.37,10.33,0ZM2.95,10.26l5.35,2.21c-.78,1.89-.78,4.01,0,5.9l-5.35,2.22c-1.37-3.31-1.37-7.02,0-10.33Zm7.31,17.64l2.21-5.34c1.89,.78,4.01,.78,5.9,0l2.21,5.34c-3.31,1.37-7.02,1.37-10.33,0h0Z"
          />
        </svg>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Livline</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      link
      :to="{ name: 'det_sker', params: { slug: this.$route.params.slug } }"
    >
      <v-list-item-icon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.2 37.53">
          <path
            fill="none"
            stroke="#6e7c8f"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2px"
            d="M22.12,18.12l-7.8-4.68v-3.55h3.33c.61,0,1.11-.5,1.11-1.11h0v-2.22c0-.61-.5-1.11-1.11-1.11h-3.34V2.11c0-.61-.5-1.11-1.11-1.11h-2.22c-.61,0-1.11,.5-1.11,1.11h0v3.33h-3.33c-.61,0-1.11,.5-1.11,1.11v2.22c0,.61,.5,1.11,1.11,1.11h3.33v3.55l-7.8,4.68c-.67,.4-1.08,1.12-1.08,1.9v16.51H7.66v-6.66c0-2.45,1.99-4.44,4.44-4.44s4.44,1.99,4.44,4.44v6.66h6.66V20.02c0-.78-.41-1.5-1.08-1.9Z"
          />
        </svg>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Det sker i<br />{{ church.name }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "det-sker-page",
  computed: {
    church() {
      return this.$store.getters["churches/getChurch"](this.$route.params.slug);
    },
  },
};
</script>

<style scoped>
.v-list-item__title {
  font-weight: 700;
}
.v-list-item__icon svg {
  display: inline-block;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  height: 32px;
  max-width: 24px;
}

.theme--light.v-list-item .v-list-item__icon svg {
  color: rgba(0, 0, 0, 0.54);
}

.theme--light.v-list-item.v-list-item--active .v-list-item__icon svg {
  color: rgba(0, 0, 0, 0.78);
}

.theme--dark.v-list-item .v-list-item__icon svg {
  color: #fff;
}

.v-list .v-list-item__icon {
  align-self: center;
  justify-content: center;
  margin: 8px !important;
}

.v-list .v-list-item--active.theme--dark,
.v-list .v-list-item--active .v-icon.theme--dark {
  stroke: #fff;
  fill: #fff;
  color: #fff;
}
</style>
<style>
#udforskMere .v-list-group__header {
  padding-left: 8px !important;
}

#udforskMere .v-list-group--sub-group .v-list-group__items .v-list-item {
  padding-left: 20px !important;
}
</style>
