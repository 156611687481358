<template>
  <div class="d-flex justify-center badge-outer p-3">
    <div
      class="ring d-flex align-center justify-center"
      :class="{
        ringLevel0: level == 0,
        ringLevel1: level == 1,
        ringLevel2: level == 2,
        ringBold: ringBold,
      }"
    >
      <img
        :src="image_path"
        :style="[level == 0 ? { filter: 'grayscale(100%)' } : {}]"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    image_path: String,
    level: Number,
    ringBold: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.badge-outer {
  overflow: auto;
  aspect-ratio: 1 / 1;
  height: 100%;
}
.ring {
  margin-top: 3px;
  max-width: calc(100% - 10px);
  height: calc(100% - 10px);
  aspect-ratio: 1 / 1;
  box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 24%);
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  border: solid 1vh transparent;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border-color: 179, 179, 179;
}

.ringBold {
  border-width: 1.5vh;
}

.ringLevel0 {
  border-color: rgb(179, 179, 179);
}
.ringLevel1 {
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, rgb(234, 234, 234), rgb(194, 194, 194));
}
.ringLevel2 {
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, rgb(255, 228, 180), rgb(249, 166, 20));
}

.ring img {
  height: 70%;
  width: 70%;
}
</style>
