<template>
  <div class="wrapper">
    <div>Loading...</div>

    <portal to="app-bar-title">Kirkebesøg</portal>
  </div>
</template>

<script>
export default {
  name: "root-home-page",
  created() {
    window.location.href = "https://vorherrebevars.dk/kirkebesog/";
  },
};
</script>
