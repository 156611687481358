<template>
  <div class="wrapper container" style="padding: 0px !important; height: 100%">
    <div id="intro" class="font-weight-black mt-3">Vælg et spor ...</div>
    <div id="grid" class="activities" v-if="church && church.activities">
      <router-link
        v-for="activity in orderedActivities"
        :key="activity.id"
        :to="{
          name: 'activity',
          params: { slug: $route.params.slug, activitySlug: activity.slug },
        }"
        style="text-decoration: none; color: unset; font-size: 18px"
      >
        <div class="activity">
          <img v-if="activity.image_path" :src="activity.image_path" />
          <div class="overlay"></div>
          <h3>
            {{ activity.name }}
          </h3>
        </div>
      </router-link>
    </div>

    <portal to="app-bar-title" v-if="church !== 404">{{ church.name }}</portal>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "home-page",
  data() {
    return {
      forceRefresh: 0,
    };
  },

  computed: {
    church() {
      this.forceRefresh; // just referencing it is enough!
      return this.$store.getters["churches/getChurch"](this.$route.params.slug);
    },
    orderedActivities: function () {
      return _.orderBy(this.church.activities, "id");
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "churches/addChurch") {
        this.forceRefresh++;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  components: {},
};
</script>
<style scoped>
#intro {
  text-align: center;
}
.activities {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 12px;
  padding-bottom: 22px;
}
@media only screen and (max-width: 768px) {
  .activities {
    grid-template-columns: 1fr;
  }
}
.activity {
  background-color: #00000029;
  position: relative;
  width: 100%;
  border-radius: 0.75rem;
  box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 24%);
}
.activity::before {
  display: block;
  padding-top: 56.25%;
  content: "";
}
.activity > .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0.75rem;
  background: linear-gradient(201deg, transparent, rgba(57, 57, 57, 0.42));
}

.activity > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  object-fit: cover;
}

.activity > h3 {
  position: absolute;
  color: white;
  bottom: 2rem;
  left: 2rem;
  font-size: 2rem;
  font-weight: 900;
}
</style>
