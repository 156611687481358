import Vue from "vue";
import VueRouter from "vue-router";
import RootHomeView from "../views/RootHomeView.vue";
import HomeView from "../views/HomeView.vue";
import TaskView from "../views/TaskView.vue";
import ActivityView from "../views/ActivityView.vue";
import ChurchView from "../views/ChurchView.vue";
import DetSkerView from "../views/DetSkerView.vue";
import LivlineView from "../views/LivlineView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "root-home",
    component: RootHomeView,
  },
  {
    path: "/:slug",
    component: ChurchView,
    children: [
      {
        path: "/",
        name: "home",
        component: HomeView,
      },
      {
        path: "spor/:activitySlug",
        name: "activity",
        component: ActivityView,
      },
      {
        path: "spor/:activitySlug/opgave/:taskId",
        name: "task",
        component: TaskView,
      },
      {
        path: "livline",
        name: "livline",
        component: LivlineView,
      },
      {
        path: "det_sker",
        name: "det_sker",
        component: DetSkerView,
      },
      {
        path: "scan/:data",
        redirect: "/:slug",
      },
      // {
      //   path: "nationale_badges",
      //   name: "nationale_badges",
      //   component: NationaleBadgesView,
      // },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
