<template>
  <v-app id="inspire" class="myFont">
    <MySnackbar />
    <main v-if="!loading" style="height: 100%">
      <v-navigation-drawer
        v-if="church !== 404"
        v-model="drawer"
        app
        right
        floating
        temporary
        style=""
      >
        <v-list-item class="px-1 mb-1">
          <v-list-item-content> </v-list-item-content>
          <v-list-item-action class="ma-2 mr-0">
            <v-btn icon large @click="drawer = !drawer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-x"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="#6D7C8F"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                width="32"
                height="32"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <NavigationList />
        <div class="text-center mt-10 mb-2">
          <a
            href="https://vorherrebevars.dk"
            target="_blank"
            style="text-decoration: none; color: unset"
          >
            <span style="font-size: 10px">Udviklet af</span>
            <v-img
              :src="require('../assets/vorherrebevars.png')"
              contain
              height="30"
            />
          </a>
        </div>
      </v-navigation-drawer>

      <v-app-bar color="primary" app dark style="z-index: 198">
        <v-btn v-if="canGoBack" @click="goBack" icon>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
            <path
              d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"
            />
          </svg>
        </v-btn>

        <div v-else style="width: 48px; margin-left: -12px">
          <v-img
            :src="require('../assets/folkekirken.svg')"
            contain
            height="40"
          />
        </div>

        <v-toolbar-title class="flex-grow-1" style="padding: 0px">
          <portal-target
            name="app-bar-title"
            class="d-flex justify-center font-weight-bold pt-2"
          >
            {{ church.name }}
          </portal-target>
        </v-toolbar-title>
        <v-app-bar-nav-icon @click="drawer = !drawer" v-if="church !== 404">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path
              d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z"
            />
          </svg>
        </v-app-bar-nav-icon>

        <div v-else style="width: 48px"></div>
      </v-app-bar>

      <v-main style="height: 100%">
        <div class="d-flex justify-center mt-6" v-if="church === 404">
          Vi kunne ikke finde det du søger
        </div>
        <router-view></router-view>
      </v-main>
    </main>
    <main v-else>
      <div
        class="d-flex align-center justify-center flex-column"
        style="width: 100vw; height: 100vh"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
        <div class="font-weight-bold mt-3">Loading...</div>
      </div>
    </main>
  </v-app>
</template>

<script>
import NavigationList from "../NavigationList.vue";
import MySnackbar from "./components/MySnackbar.vue";
export default {
  name: "church-page",
  data: () => ({
    loading: true,
    drawer: null,
    backArrow: true,
    forceRefresh: 0,
  }),
  mounted() {
    Promise.all([
      this.$store.dispatch("churches/getChurch", {
        slug: this.$route.params.slug,
      }),
    ]).then(() => {
      this.loading = false;
    });
  },
  computed: {
    canGoBack: function () {
      return this.$route.name != "home";
    },
    church() {
      this.forceRefresh; // just referencing it is enough!
      return this.$store.getters["churches/getChurch"](this.$route.params.slug);
    },
  },
  methods: {
    goBack() {
      if (this.$route.name != "home") {
        this.$router.go(-1);
      }
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "churches/addChurch") {
        this.forceRefresh++;
      }
    });
    document.title = this.church.name + " | Kirkebesøg";
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  components: { NavigationList, MySnackbar },
};
</script>

<style scoped>
.v-navigation-drawer {
  z-index: 199;
  clip-path: path(
    "M 42.266 380.673 C -21.236 304.018 -11.38 148.511 57.988 0 L 265.229 0 C 267.311 0 269 2.115 269 4.726 L 268.379 396.093 C 161.436 434.797 93.709 442.771 42.266 380.673 Z"
  );
  width: 269;
  height: 426;
  padding-left: 30px;
}
.v-app-bar svg {
  stroke: #fff;
  fill: #fff;
  width: 2em;
  height: 2em;
}
</style>

<style>
@import url("https://fonts.googleapis.com/css2?family=Martel:wght@400;600;700;900&display=swap");
* {
  font-family: "Martel", serif;
}
.v-text-field .v-input__slot {
  border: 2px solid #003255;
}
.wrapper {
  padding: 30px;
}
</style>
