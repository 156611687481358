<template>
  <div class="wrapper container" style="padding-bottom: 40px">
    <div class="justify-center row">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6">
        <div class="text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style="height: 100px"
            viewBox="0 0 24.2 37.53"
          >
            <path
              fill="none"
              stroke="#6e7c8f"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2px"
              d="M22.12,18.12l-7.8-4.68v-3.55h3.33c.61,0,1.11-.5,1.11-1.11h0v-2.22c0-.61-.5-1.11-1.11-1.11h-3.34V2.11c0-.61-.5-1.11-1.11-1.11h-2.22c-.61,0-1.11,.5-1.11,1.11h0v3.33h-3.33c-.61,0-1.11,.5-1.11,1.11v2.22c0,.61,.5,1.11,1.11,1.11h3.33v3.55l-7.8,4.68c-.67,.4-1.08,1.12-1.08,1.9v16.51H7.66v-6.66c0-2.45,1.99-4.44,4.44-4.44s4.44,1.99,4.44,4.44v6.66h6.66V20.02c0-.78-.41-1.5-1.08-1.9Z"
            />
          </svg>
          <h1 class="mt-10 mb-4" style="line-height: 1">
            Det sker i <br />{{ church.name }}
          </h1>
        </div>
        <div v-html="church.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "det-sker-page",
  computed: {
    church() {
      return this.$store.getters["churches/getChurch"](this.$route.params.slug);
    },
  },
};
</script>
