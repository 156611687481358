import axios from "axios";
export default {
  getChurches(cb) {
    axios.get("churches").then((response) => {
      cb(response.data);
    });
  },
  getChurch(slug, cb) {
    axios
      .get(`churches/${slug}`)
      .then((response) => {
        console.log(response.data);
        cb(response.data);
      })
      .catch((e) => {
        console.log("getChurch catch", e);
        cb(null);
      });
  },
};
