import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

const opts = {
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#6D7C8F",
        secondary: "#003255",
        accent: "#D22832",
        danger: "#D22832",
        background: colors.grey.lighten4,
      },
    },
  },
};

export default new Vuetify(opts);
